import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";

const TermsConditions = () => {

  // const websiteUrl = "https://plento.io"
  // const EmailUrl = "support@plento.io"
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title variant="card" fontSize="2rem" mb="30px" className="text-center">
                  PRIVACY POLICY
                </Title>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="1rem" mb="30px">
                    Effective Date: 01.09.2022
                  </Title>
                  <Text variant="small">
                    This “Privacy Policy” explains how Plento Inc. (“<b>Company</b>” or “<b>we</b>”) collects, uses, discloses, and otherwise processes personal data on behalf of our customers – typically, merchants (any, a “<b>Merchant</b>”) – in connection with our application, Plento Online Ordering, Plento Reservations & Plento Restaurant Software, which runs on the Clover Point of Sale system (“<b>Clover POS</b>”). This Privacy Policy does not apply to Company’s privacy practices in any other context.
                  </Text>
                  <Text variant="small">
                    Company’s processing of personal data in connection with our application is governed by this Privacy Policy and our agreements with Merchants. In the event of any conflict between this Privacy Policy and a customer agreement, the customer agreement will control to the extent permitted by applicable law.
                  </Text>
                  <Text variant="small">
                    This Privacy Policy is not a substitute for any privacy policy that a Merchant may be required to provide to their customers, personnel, or other individuals.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Information We Collect
                  </Title>
                  <Text variant="small">
                    We may collect personal data from or on behalf of Merchants. Merchants determine the scope of the personal data transferred to us or that we collect, and the information we receive may vary by Merchant. Typically, the information we collect on behalf of Merchants includes:
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Information that we collect when a Merchant’s customers make a payment
                  </Title>
                  <Text variant="small">
                    When a customer makes a payment via a Clover POS, we collect information about the transaction, which may include personal data. Information about transactions includes the payment card used, name associated with the payment card, the location of the merchant’s store, date and time of the transaction, transaction amount, and information about the goods or services purchased in the transaction.
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Additional information Merchants’ customers provide through the Clover POS ancillary to a payment
                  </Title>
                  <Text variant="small">
                    We may collect additional information ancillary to the payment. This information may include:
                  </Text>
                  <List>
                    <li>Customers’ email address or phone number, such as when the customer chooses to receive an electronic receipt</li>
                    <li>Customers’ marketing preferences, such as whether the customer wishes to receive marketing communications or newsletters</li>
                    <li>Information about participating customers’ activity in a merchant loyalty program</li>
                    <li>Customers’ physical address, where needed for delivery of goods or services</li>
                    <li>Other information the customer provides, such as birthdate, interests or preferences, reviews, and feedback</li>
                  </List>
                  <Title variant="card" fontSize="1rem" mb="30px">
                    Information that we collect about Merchants’ personnel
                  </Title>
                  <Text variant="small">
                    We may collect information about Merchants’ personnel and interactions with the Clover POS, such as clock-in and clock-out time and tips earned.
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Additional information that Merchants provide to us about their customers or personnel
                  </Title>
                  <Text variant="small">
                    Merchants may provide us with additional information directly, via access they grant to us, or otherwise. The types of information that merchants may provide to us about their customers include email addresses, phone numbers, and purchase history. The types of information that merchants may provide to us about their personnel include email addresses, phone numbers, shifts, and sales.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    How We Use the Information We Collect
                  </Title>
                  <Text variant="small">
                    We use the personal data we collect for or on behalf of Merchants, to provide our services and the functionality of our application:
                  </Text>
                  <Text variant="small">
                    We may also use personal data for related internal purposes, including:
                  </Text>
                  <List>
                    <li>To provide information about the application, such as important updates or changes to the application and security alerts</li>
                    <li>To measure performance of and improve the application</li>
                    <li>To respond to inquiries, complaints, and requests for customer support</li>
                  </List>
                  <Text variant="small">
                    In addition, Company may use personal data as we believe necessary or appropriate to (a) comply with applicable laws and lawful requests and legal processes, such as to respond to subpoenas or requests from government authorities; (b) enforce the terms and conditions that govern our application; (c) protect our rights, privacy, safety or property, and/or that of you or others; and (d) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    How We Share Information
                  </Title>
                  <Text variant="small">
                    We may share personal data that we collect with:
                  </Text>
                  <List>
                    <li>The Merchant from whom or on whose behalf we collected the personal data</li>
                    <li>The platform on which our application runs, the Clover POS. You may view Clover’s Privacy Notice here.</li>
                    <li>With third parties as a Merchant may direct</li>
                    <li>With third party service providers that help us manage and improve the application</li>
                    <li>With Company subsidiaries and corporate affiliates for the purposes described in this Privacy Policy or in our agreement with a Merchant</li>
                  </List>
                  <Text variant="small">
                    Company may disclose personal data to government or law enforcement officials or private parties as required by law, and disclose and use such information as we believe necessary or appropriate to (a) comply with applicable laws and lawful requests and legal processes, such as to respond to subpoenas or requests from government authorities; (b) enforce the terms and conditions that govern our application; (c) protect our rights, privacy, safety or property, and/or that of you or others; and (d) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.
                  </Text>
                  <Text variant="small">
                    Company may sell or transfer some or all of its business or assets, including your personal data, in connection with a business transaction (or potential business transaction) such as a merger, consolidation, acquisition, reorganization or sale of assets or in the event of bankruptcy, in which case we will make reasonable efforts to require the recipient to honor this Privacy Policy.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Your Rights and Choices
                  </Title>
                  <Title variant="card" fontSize="1rem" mb="30px">
                    Data Subject Rights
                  </Title>
                  <Text variant="small">
                    To the extent that applicable law provides individuals with rights pertaining to their personal information, such as to review and request changes to their personal information, individuals should contact the Merchant with any requests pertaining to the Merchant’s use of our application. To the extent that Clover is responsible for responding to data subject rights requests under applicable law, individuals may contact Clover with applicable requests as explained in Clover’s Privacy Notice, <a className="ml-1 white" rel="noreferrer" href="https://www.clover.com/privacy-policy" target="_blank">https://www.clover.com/privacy-policy</a>. Company will assist a Merchant, or Clover, as applicable, in responding to such requests subject to our contract with a Merchant or Clover.
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Complaints
                  </Title>
                  <Text variant="small">
                    If you have a complaint about our handling of personal data, you may contact us via the contact information provided below.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Updates
                  </Title>
                  <Text variant="small">
                    We reserve the right to modify this Privacy Policy at any time. We will notify you of updates by updating the date of this Privacy Policy.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Contact Us
                  </Title>
                  <Text variant="small">
                    You may contact us with any questions, comments, or complaints, about this Privacy Policy or our privacy practices via:
                  </Text>
                  <br className="d-none d-sm-block" />
                  <Text variant="small" >
                    Attn: Privacy Officer
                  </Text>
                  <Text variant="small" >
                    18 King Street #1400,
                  </Text>
                  <Text variant="small" >
                    Toronto, ON, M5C 1C4
                  </Text>
                  <br className="d-none d-sm-block" />
                  <Text variant="small" >
                    Or email at support@plento.io
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Additional Information for Merchants Located in Europe
                  </Title>
                  <Title variant="card" fontSize="1rem" mb="30px">
                    Controller
                  </Title>
                  <Text variant="small">
                    Company is a data processor acting for and on behalf of the Merchant that has installed our application on their Clover POS. That Merchant is the controller of personal data that we process on its behalf. Clover is also a controller of personal data in some circumstances. Clover’s Privacy Notice is available at <a className="ml-1 white" rel="noreferrer" href="https://www.clover.com/privacy-policy" target="_blank">https://www.clover.com/privacy-policy</a>.
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Legal Basis for Processing
                  </Title>
                  <Text variant="small">
                    Company processes personal data as directed or permitted by the Merchant that uses our application. The Merchant is responsible for establishing a legal basis for our processing of personal data for or on behalf of the Merchant.
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Cross Border Data Transfer
                  </Title>
                  <Text variant="small">
                    When we transfer personal data outside of Europe (or the UK) to countries not deemed by the European Commission to provide an adequate level of protection for personal data, we make the transfer pursuant to one of the following transfer mechanisms:
                  </Text>
                  <List>
                    <li>A contract approved by the European Commission (sometimes called “Model Clauses” or “Standard Contractual Clauses”);</li>
                    <li>The recipient’s Binding Corporate Rules;</li>
                    <li>The consent of the individual to whom the personal data relates; or</li>
                    <li>Other mechanisms or legal grounds as may be permitted under applicable European law.</li>
                  </List>
                  <Text variant="small" >
                    You may contact us with questions about our transfer mechanism.
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Data Retention
                  </Title>
                  <Text variant="small">
                    Subject to our agreement with a Merchant, Company retains personal data for as long as necessary to (a) provide our products and services; (b) comply with legal obligations; (c) resolve disputes; and (d) enforce the terms of any agreement we may have with a Merchant. You may contact us for additional information about our data retention practices in connection with the application.
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Data Subject Rights
                  </Title>
                  <Text variant="small">
                    Under certain circumstances, data subjects in Europe and the UK have certain rights relating to their personal data, which include the rights to request from the Controller (a) access to the data subject’s personal data; (b) correction of incomplete or inaccurate personal data; (c) erasure of personal data; (d) restriction of processing concerning the data subject; and (e) that the controller provide a copy of the data subject’s personal data that the data subject provided to the controller in a structured, commonly used and machine-readable format. Data subjects may also object to a controller’s processing of personal data under certain circumstances. Where processing is based on a data subject’s consent, the data subject has the right to withdraw consent at any time; however, the withdrawal of consent will not affect the lawfulness of processing based on consent before its withdrawal. Data subjects may also file a complaint with a supervisory authority. You may view contact information for supervisory authorities at <a className="ml-1 white" rel="noreferrer" href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank">https://edpb.europa.eu/about-edpb/board/members_en</a>. Data subjects in Europe or the UK should direct any rights request to the appropriate Controller.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Your California privacy rights
                  </Title>
                  <Text variant="small">
                    As a California resident, you have the rights listed below. However, these rights are not absolute, and we may decline your request as permitted by the CCPA.
                  </Text>
                  <List>
                    <li>
                      Information. You can request the following information about how we have collected and used your Personal Information during the past 12 months:
                    </li>
                  </List>
                  <List>
                    <ol>i. The categories of Personal Information that we have collected.</ol>
                    <ol>ii. The categories of sources from which we collected Personal Information.</ol>
                    <ol>iii. The business or commercial purpose for collecting and/or selling Personal Information.</ol>
                    <ol>iv. The categories of third parties with whom we share Personal Information.</ol>
                    <ol>v. Whether we have disclosed your Personal Information for a business purpose, and if so, the categories of Personal Information received by each category of recipient.</ol>
                    <ol>vi. Whether we’ve sold your Personal Information; and, if so, the categories of Personal Information received by each category of recipient.</ol>
                  </List>
                  <List>
                    <li>Access. You can request a copy of the Personal Information that we maintain about you.</li>
                    <li>Deletion. You can ask us to delete the Personal Information that we maintain about you.</li>
                    <li>Non-discrimination. You are entitled to exercise the rights described above free from discrimination. This means that we will not penalize you for exercising your rights by taking actions such as by denying you goods or services, increasing the price/rate of goods or services, decreasing the service quality, or suggesting that we may penalize you as described above for exercising your rights. However, the CCPA allows us to charge you a different price or provide a different service quality if that difference is reasonably related to the value of the Personal Information, we are unable to use.</li>
                  </List>

                  <Title variant="card" fontSize="1rem" mb="30px">
                    How to exercise your rights
                  </Title>
                  <Text variant="small">
                    You may exercise your California privacy rights as follows:
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Right to information, access and deletion
                  </Title>
                  <Text variant="small">
                    You can request to exercise your information, access and deletion rights in the following ways:
                  </Text>
                  <List>
                    <li>Call 1(866) 939-4866</li>
                    <li>Identity verification. The CCPA requires us to verify the identity of the individual submitting the request before providing a substantive response to the request. A request must be provided with sufficient detail to allow us to understand, evaluate and respond. The requester must provide sufficient information to allow us to reasonably verify that the individual is the person about whom we collected information. A request may also be made on behalf of your child under 13.</li>
                    <li>Authorized agents. California residents can empower an “authorized agent” to submit requests on their behalf. We may require the authorized agent to have a written authorization confirming that authority.</li>
                  </List>
                  <Title variant="card" fontSize="1rem" mb="30px">
                    Sale of personal information
                  </Title>
                  <Text variant="small">
                    We do not sell, as defined under CCPA, your Personal Information to third parties.
                    <br className="d-none d-sm-block" />
                    <br className="d-none d-sm-block" />
                    In the preceding twelve (12) months, we have not sold any personal information.
                  </Text>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Personal information that we collect, use and share
                  </Title>
                  <Text variant="small">
                    The chart below summarizes our collection, use and sharing of Personal Information during the last 12 months before the effective date of this Privacy Policy. We describe the sources through which we collect your Personal Information in section above titled The Personal Data We Collect, and describe the purposes for which we collect, use, sell and share this information in section above titled How We Use Your Personal Data and The Parties With Whom We Share Your Personal Data.
                  </Text>
                  <br className="d-none d-sm-block" />
                  <table border="1" cellPadding="5">
                    <thead>
                      <tr><th>Category (see the glossary below for definitions)</th><th>Do we collect this information?</th><th>Do we share this information for business purposes?</th></tr>
                    </thead>
                    <tbody style={{ fontSize: '0.9rem' }}>
                      <tr><td>Identifiers</td><td>Yes</td><td>Yes</td></tr>
                      <tr><td>Online Identifiers</td><td>Yes</td><td>Yes</td></tr>
                      <tr><td>Protected Classification Characteristics</td><td>No</td><td>No</td></tr>
                      <tr><td>Commercial Information</td><td>No</td><td>No</td></tr>
                      <tr><td>Biometric Information</td><td>No</td><td>No</td></tr>
                      <tr><td>Internet or Network Information</td><td>Yes</td><td>Yes</td></tr>
                      <tr><td>Geolocation Data</td><td>Yes</td><td>Yes</td></tr>
                      <tr><td>Sensory Information</td><td>No</td><td>No</td></tr>
                      <tr><td>Professional or Employment Information</td><td>No</td><td>No</td></tr>
                      <tr><td>Education Information</td><td>No</td><td>No</td></tr>
                      <tr><td>Inferences</td><td>No</td><td>No</td></tr>
                      <tr><td>Financial Information</td><td>No</td><td>No</td></tr>
                      <tr><td>Medical Information</td><td>No</td><td>No</td></tr>
                    </tbody>
                  </table>
                  <Title variant="card" fontSize="1rem" mb="30px" mt="30px">
                    Glossary
                  </Title>
                  <table border="1" cellPadding="5">
                    <thead>
                      <tr><th>Category</th><th>Definition</th></tr>
                    </thead>
                    <tbody style={{ fontSize: '0.9rem' }}>
                      <tr><td>Categories of Personal Information</td><td>Date Elements within the Category</td></tr>

                      <tr><td>Biometric Information</td><td>An individual’s physiological, biological or behavioral characteristics, including DNA, that can be used, singly or in combination with each other or with other identifying data, to establish an individual’s identity. Biometric information includes, but is not limited to, imagery of the iris, retina, fingerprint, face, hand, palm, vein patterns, and voice recordings, from which an identifier template, such as a face print, a minutiae template, or a voiceprint, can be extracted, and keystroke patterns or rhythms, gait patterns or rhythms, and sleep, health, or exercise data that contain identifying information.</td></tr>
                      <tr><td>Transaction History</td><td>Products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td></tr>
                      <tr><td>Financial Information</td><td>Bank account number, debit or credit card numbers, insurance policy number, and other financial information.</td></tr>
                      <tr><td>Geolocation Data</td><td>Precise location, e.g., derived from GPS coordinates or telemetry data.</td></tr>
                      <tr><td>Identifiers</td><td>Real name, alias, postal address, unique personal identifier, customer number, email address, account name other similar identifiers.
                        Government-issued ID	Social security number, driver’s license, passport, or other government-issued ID, including an ID number or image.
                        Medical Information	Personal information about an individual’s health or healthcare, including health insurance information.</td></tr>
                      <tr><td>Internet or Network Information</td><td>Browsing history, search history, and information regarding a consumer’s interaction with an Internet website, application, or advertisement.</td></tr>
                      <tr><td>Online Identifiers</td><td>An online identifier or other persistent identifier that can be used to recognize a person, family or device, over time and across different services, including but not limited to, a device identifier; an Internet Protocol address; cookies, beacons, pixel tags, mobile ad identifiers, or similar technology; customer number, unique pseudonym, or user alias; telephone numbers, or other forms of persistent or probabilistic identifiers (i.e., the identification of a person or a device to a degree of certainty of more probable than not) that can be used to identify a particular person or device.</td></tr>
                      <tr><td>Physical Description</td><td>An individual’s physical characteristics or description (e.g., hair color, eye color, height, weight).</td></tr>
                      <tr><td>Professional or Employment Information</td><td>Information relating to a person's current, past or prospective employment or professional experience (e.g., job history, performance evaluations), and educational background.</td></tr>
                      <tr><td>Protected Classification Characteristics</td><td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td></tr>
                      <tr><td>Sensory Information</td><td>Audio, electronic, visual, thermal, olfactory, or similar information.</td></tr>
                    </tbody>
                  </table>
                  <Title variant="card" fontSize="1rem" mb="10px" mt="30px">
                    Plento Inc.
                  </Title>
                  <Text variant="small" >
                    18 King Street #1400,
                  </Text>
                  <Text variant="small" >
                    Toronto, ON, M5C 1C4
                  </Text>
                  <Text variant="small" >
                    support@plento.io
                  </Text>
                  <Text variant="small" >
                    (866) 939-4866
                  </Text>
                </Box>


              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
